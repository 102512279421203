<script setup lang="ts">
import { Collapse } from "vue-collapsed";
import { google, outlook, ics } from "calendar-link";

const props = defineProps<{
  title: string;
  calendarDescription?: string;
  location?: string;
  startDateTime?: string;
  endDateTime?: string;
}>();

const showCalendar = ref(false);

const event = computed(() => ({
  title: props.title,
  description: props.calendarDescription ?? "",
  start: props.startDateTime
    ? new Date(props.startDateTime).toISOString()
    : new Date().toISOString(),
  end: props.endDateTime ? new Date(props.endDateTime).toISOString() : new Date().toISOString(),
  location: props.location ?? "Online",
}));

const googleLink = computed(() => google(event.value));

const outlookLink = computed(() => outlook(event.value));

const icsLink = computed(() => ics(event.value));
</script>

<template>
  <UiButton
    button-type="secondary"
    button-icon="plus"
    class="mt-10"
  >
    <button @click="() => (showCalendar = !showCalendar)">
      Add to calendar
    </button>
  </UiButton>

  <Collapse :when="showCalendar">
    <div class="flex flex-col items-center gap-2 pt-4">
      <NuxtLink
        class="btn--link"
        :to="googleLink"
      >Google</NuxtLink>
      <NuxtLink
        class="btn--link"
        :to="outlookLink"
      >Outlook</NuxtLink>
      <NuxtLink
        class="btn--link"
        :href="icsLink"
      >Other</NuxtLink>
    </div>
  </Collapse>
</template>
