<script lang="ts" setup>
import type { Entry } from "contentful";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeAgendaEventSkeleton } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";
import { formatTime } from "~/lib/date-time";

defineProps<{
  item: Entry<TypeAgendaEventSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const showDescription = ref(false);
</script>

<template>
  <UiContainer
    no-padding
    no-horisontal-padding
  >
    <div class="relative grid grid-cols-12 gap-y-4 md:gap-y-6">
      <div class="col-span-12 flex items-center lg:col-span-2 lg:col-start-2">
        <h4 class="mb-4 w-10/12 lg:hidden">
          {{ item.fields.title }}
        </h4>
        <div class="flex-center flex flex-col gap-y-2">
          <div
            v-if="item.fields.location"
            class="flex items-center gap-x-2"
          >
            <NuxtIcon
              name="ussif:location"
              class="size-4 text-blue"
            />
            {{ item.fields.location }}
          </div>
          <div
            v-if="item.fields.startTime && item.fields.endTime"
            class="flex items-center gap-x-2"
          >
            <NuxtIcon
              name="ussif:clock"
              class="size-4 text-blue"
            />
            {{ item.fields.startTime ? formatTime(item.fields.startTime) : "" }} -
            {{ item.fields.endTime ? formatTime(item.fields.endTime) : "" }}
          </div>
          <div
            v-if="item.fields.speakers && item.fields.speakers.length > 0"
            class="flex items-center gap-x-3"
          >
            <NuxtIcon
              name="ussif:mic"
              class="size-4 min-w-4 text-blue"
            />
            <div class="">
              <span class="mr-1">By</span>
              <div
                v-for="(speaker, index) in item.fields.speakers"
                :key="index"
                class="inline"
              >
                {{ speaker?.fields.name
                }}<span
                  v-if="item.fields.speakers && index != item.fields.speakers.length - 1"
                  class="mr-2"
                >,</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:relative lg:col-span-7 lg:col-start-5">
        <div
          v-if="item.fields.speakers && item.fields.speakers.length > 0"
          class="absolute right-0 top-0 flex size-8 cursor-pointer items-center justify-center rounded-full bg-blue transition lg:size-10"
          :class="{
            'rotate-180': showDescription,
          }"
          @click="() => (showDescription = !showDescription)"
        >
          <NuxtIcon
            v-if="!showDescription"
            name="ussif:plus"
            class="size-5 min-w-4 text-white lg:size-6"
          />
          <NuxtIcon
            v-else
            name="ussif:minus"
            class="size-5 min-w-4 text-white lg:size-6"
          />
        </div>
        <h4 class="hidden lg:block">
          {{ item.fields.title }}
        </h4>
        <div
          v-if="item.fields.speakers && item.fields.speakers.length > 0"
          class="mb-6 flex flex-row gap-x-3 lg:my-6"
        >
          <div
            v-for="(speaker, index) in item.fields.speakers"
            :key="index"
          >
            <NuxtImg
              v-if="speaker && speaker.fields"
              class="rounded-full"
              :src="speaker.fields.thumbnail?.fields.file?.url"
              :alt="
                typeof speaker.fields.description === 'string'
                  ? speaker.fields.description
                  : 'speaker image'
              "
              :width="58"
              :height="58"
              fit="contain"
              loading="lazy"
            />
          </div>
        </div>
        <Collapse :when="showDescription">
          <RichTextRenderer
            :document="item.fields.description"
            :node-renderers="nodeRenderers"
          />
        </Collapse>
      </div>
    </div>
  </UiContainer>
</template>
